<template>
  <div class="misc-wrapper error-404">
    <b-link
      class="brand-logo d-flex align-items-center"
    >
      <b-img
        fluid
        alt="img"
        :class="appName ? 'logo-img' : 'logo-img-full'"
        :src="logo"
      />
      <h6 class="mb-0 app-name">
        {{ appName }}
      </h6>
    </b-link>
    <div v-if="errorMessage">
      <div class="warning-message d-flex align-items-center">
        <img
          class="alert-img mr-1"
          alt="alert"
          :src="require('@/assets/images/common/ic-alert.svg')"
        >
        <span>
          Error: {{ errorMessage }}
        </span>
      </div>
      <div class="text-center mt-2">
        <user-hint />
        <br>
        <b-button
          variant="flat-secondary"
          size="sm"
          pill
          style="margin-top: 6px"
          to="/"
        >
          <feather-icon
            size="16"
            icon="ArrowLeftIcon"
            class="mr-50"
          />
          Back to Home
        </b-button>
      </div>
    </div>
    <div
      v-else-if="successMessage"
    >
      <div class="warning-message d-flex align-items-center">
        <b-img
          rounded
          class="mr-50"
          :src="require('@/assets/images/pages/overview/ic-success.png')"
          style="width: 30px"
        />
        <h5 class="mb-0">
          {{ successMessage }}
        </h5>
      </div>
      <div class="d-flex justify-content-center">
        <b-button
          class="btn-submit mt-1"
          pill
          size="sm"
          @click="$router.push('/my-ads-account')"
        >
          {{ $t('addFundPage.creditCardPayment.textShowMeAdAccount') }}
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable global-require,camelcase */
import {
  BLink, BImg, BButton,
} from 'bootstrap-vue'
import envMixin from '@/mixins/envMixin'
import UserHint from '@/components/UserHint.vue'

export default {
  components: {
    BLink,
    BImg,
    BButton,
    UserHint,
  },
  mixins: [envMixin],
  data() {
    return {
      downImg: require('@/assets/images/pages/error-404.png'),
      errorMessage: this.$route?.query?.error || '',
      successMessage: this.$route?.query?.message || '',
    }
  },

  computed: {
    appName() {
      return process.env.VUE_APP_LOGO_NAME
    },

  },

  mounted() {
    this.$nextTick(() => {
      const plugin = document.createElement('script')
      plugin.setAttribute(
        'src',
        'https://wchat.freshchat.com/js/widget.js',
      )
      plugin.onload = () => {
        if (window.fcWidget) {
          window.fcWidget.init({
            token: 'b7c5a45f-b29b-4853-ac30-950b2842854a',
            host: 'https://wchat.freshchat.com',
            locale: 'en',
          })
        }
      }
      plugin.async = true
      return document.head.appendChild(plugin)
    })
  },
}
</script>

<style lang="scss">
  @import '@core/scss/vue/pages/page-misc.scss';
</style>
<style scoped lang="scss">
  .logo-img{
    max-width: 50px;
  }

  .logo-img-full {
    width: 135px;
  }

  .app-name{
    margin-left: .5rem;
    max-width: 100px;
  }

  .alert-img {
    width: 18px;
  }

  .warning-message {
    margin-top: 10px;
    background: rgba(251, 176, 59, 0.1);
    border-radius: 10px;
    padding: 15px 12px 15px 16px;
  }
</style>
